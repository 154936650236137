.carosel-container{
    margin: auto;
    padding-top: 5%;;
}

.image-label{
    font-size: xx-large !important;
}

.accordion-container{
    max-width: 50%;
    margin: auto;
    padding-top: 5%;
}